<template>
  <div class="bmi-and-weight-list-page w-100">
    <b-container fluid class="bmi-and-weight-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <template-table
                :column="column"
                :data="data"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                :searchAction="false"
                :pagingAction="false"
                @search="searchRequest"
                @reset="resetRequest"
                @sortBy="sortRequest"
              >
                <template v-slot:body="{ item }">
                  <td>
                    {{ item.type }}
                  </td>
                  <td style="width: 250px">
                    <div class="d-flex align-items-center">
                      <basic-input
                        name="from"
                        inputType="number"
                        :value.sync="item.from"
                        :disabled="true"
                        v-numeric-input
                      ></basic-input>
                      <basic-input
                        name="to"
                        inputType="number"
                        :value.sync="item.to"
                        :disabled="true"
                        v-numeric-input
                      ></basic-input>
                    </div>
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                </template>
              </template-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {},
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'type',
          label: 'Phân loại mức độ gầy béo',
          sortable: false,
        },
        {
          key: 'thresholdOfStats',
          label: 'Ngưỡng chỉ số',
          sortable: false,
          style: 'text-align: right;',
        },
        {
          key: 'description',
          label: 'Ghi chú',
          sortable: false,
        },
      ],
      data: [],
    };
  },
  computed: {
    searchParams() {
      return {
        sortBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
  },
  watch: {
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    pagingAction() {
      this.loadData();
    },
    searchRequest() {
      this.loadData();
    },
    resetRequest() {
      this.loadData();
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    viewItem() {},
    editItem() {},
    deleteItem() {},
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('Bmi', {
          params: this.searchParams,
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss">
.bmi-and-weight-list-page {
  &__body {
    .table {
      .form-group {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }
  }
}
</style>
